/* colors */
$primary: #374449;
$secondary: #73cdf3;
$highlight: #2ea2f8;
$navbar: #4f5d63;

/* elements */
html {
    scroll-behavior: smooth;
}

body {
    margin: 0 auto;
    width: 100%;
    background-color: $primary;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

/* text */
h1,
h2,
h3,
h4,
h5,
p,
a {
    color: #f9f9f9;
    font-family: "Fira Code", monospace;
}
h1,
h2,
h3,
h4 {
    font-weight: normal;
}

/* General Global */
@mixin transition {
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
}
@mixin transition--l {
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
}

/* transition */
.transition {
    @include transition;
}
.longerTransition {
    @include transition--l;
}
a {
    @include transition;
}

/* navbar */
.navbar {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    width: 100%;
    background-color: $navbar;
    padding: 25px;
    padding-left: 10px;
    padding-right: 20px;
    overflow-x: hidden;
    z-index: 100;

    a {
        color: $secondary;
        font-size: 25px;
        padding: 20px;
        text-decoration: none;
        &:hover,
        &:focus {
            color: #eaeff1;
        }
    }
}
.navItems {
    float: right;
}
.hamburger {
    @include transition;
    display: none;
    float: right;
    cursor: pointer;
    &:hover,
    &:focus {
        filter: brightness(1.5);
    }
}
.highlightedNav {
    color: #d9eaf1 !important;
    &:hover,
    &:focus {
        color: $highlight !important;
    }
}
.navIcon {
    vertical-align: text-bottom;
    height: 28px;
    padding-right: 20px;
    padding-bottom: 4px;
    filter: brightness(130%);
}
/* full page nav */
.fullPageNav {
    background-color: rgba(0, 0, 0, 0.5);
    /* box-shadow: 5vw 0 40px 5vw rgb(0, 0, 0); */
    position: fixed;
    top: 0;
    left: 102vw;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    @include transition--l;
    a {
        color: $secondary;
        font-size: 48px;
        text-decoration: none;
        text-align: center;
    }

    &:hover,
    &:focus {
        color: #eaeff1;
    }
}
.navLinks {
    position: absolute;
    bottom: 0;
    right: 20px;
    text-align: right;
    padding: 20px;
}
.hamburgerActive {
    left: 0;
}
/* end of navbar */

/* blocks */
.block {
    min-height: 700px;
    height: 100vh;
}
.white {
    background-color: #f9f9f9;
}
.mainPage {
    & > div {
        min-height: 100vh;
        padding-left: 30vw;
        @include transition--l;
    }
    h2 {
        font-size: 60px;
        margin-bottom: 0;
    }
    h3 {
        font-size: 48px;
        margin: 0;
    }
    h4 {
        color: $secondary;
        font-size: 48px;
        margin: 0;
    }
}
.extraMargin {
    margin-left: 2vw;
}
/* Overlay */
.model {
    position: fixed;
    top: 5vh;
    height: 95vh;
    width: 30vw;
    min-width: 450px;
    z-index: 99;

    @include transition;
    /* allows links to be clicked even tho it is below */
    pointer-events: none;
}
.modelSmall {
    height: 50vh;
    width: 50vh;
    top: 30vh;
}
.blueSide {
    position: fixed;
    top: 0;
    width: 15vw;
    height: 100vh;
    /* border */
    border-right: 12px solid $primary;
    z-index: -10;
}
.lightBlueSide {
    width: 7vw;
    height: 100%;
    border-left: 10px solid #ade0f5;
}
/* intro */
.intro {
    display: inline-grid;
    grid-template-columns: 22vw 40vw;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-bottom: 18vh;
    h1 {
        font-size: 70px;
        color: $secondary;
    }
    .pageText {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100vh;
    }
    & > svg {
        height: 46vh;
    }
    .divider {
        border-top: 3px solid $secondary;
        width: 0%;
        transition: 0.8s;
        -moz-transition: 0.8s;
        -webkit-transition: 0.8s;
        -o-transition: 0.8s;
    }
    .dividerLoaded {
        width: 90%;
    }
    .singleText {
        font-size: 40px;
    }
}
/* about */
#about {
    display: flex;
    flex: 1;
    align-items: center;
}
.about {
    padding-right: 20vw;
    h2 {
        color: $highlight;
        margin-bottom: 10px;
    }
    a {
        color: $highlight;
        &:hover,
        &:focus {
            color: $secondary;
        }
    }
    h3,
    p {
        color: $primary;
    }
    h3 {
        font-size: 44px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    p,
    a {
        font-size: 32px;
    }
    p {
        margin-top: 0;
    }
}
/* projects */
.projects {
    padding-top: 12vh;
    p {
        font-size: 28px;
    }
    a {
        color: $highlight;
        font-size: 28px;
        &:hover,
        &:focus {
            filter: brightness(1.5);
        }
    }
}
/* single projects */
.singleProject {
    padding-bottom: 150px;
    img {
        width: 70vw;
    }
    p {
        padding-right: 10vw;
        line-height: 1.5;
        margin-bottom: 0;
    }
    h4 {
        padding-bottom: 10px;
    }
}
.endOfProjects {
    padding-bottom: 10vh;
}
/* skills */
.skills {
    padding-top: 12vh;
    padding-right: 7vw;
    p {
        font-size: 28px;
        color: $primary;
    }
    h2,
    h3 {
        color: $highlight;
    }
    #lastItem {
        padding-bottom: 10vh;
    }
}

/* contact */
.contact {
    min-height: 100vh;
    min-height: max(100vh, 550px);
    overflow: hidden;
    h2 {
        margin-top: 12vh;
        margin-bottom: 0;
        font-size: 60px;
    }
    .contactFooter {
        position: absolute;
        bottom: 2vh;
        width: 100%;
    }
}
.mainContact {
    padding-left: 30vw;
    padding-right: 15vw;
    @include transition--l;
    a {
        color: $highlight;
        font-size: 48px;
        &:hover,
        &:focus {
            filter: brightness(1.5);
        }
    }
}
.scrollDown {
    position: absolute;
    bottom: 4vh;
    width: 100%;
    &:hover,
    &:focus {
        filter: brightness(1.5);
    }
}
.contactIcons svg {
    vertical-align: bottom;
    padding-right: 20px;
    padding-top: 20px;
}

/* end of blocks */
/* other pages */
.pageIcons {
    height: 55vh;
}
.singleBody {
    display: inline-grid;
    grid-template-columns: 22vw 50vw;
    padding-left: 15vw;
    padding-right: 13vw;
    padding-top: 28vh;
    padding-bottom: 18vh;
    h1 {
        font-size: 80px;
        color: #ff4141;
    }
    p {
        font-size: 48px;
    }
    a {
        color: $highlight;
        font-size: 48px;
        &:hover,
        &:focus {
            filter: brightness(1.5);
        }
    }
    .homeIcon {
        padding-right: 30px;
        svg {
            vertical-align: sub;
        }
    }
}
.singleBodyCoin {
    grid-template-columns: 26vw 50vw;
    & > svg {
        height: 46vh;
    }
}
.rollCoin {
    -webkit-animation: spin 1s linear 1;
    -moz-animation: spin 1s linear 1;
    animation: spin 1s linear 1;
}
/* Text body */
.pageText {
    h1 {
        margin: 0;
    }
    p {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
/* resume */
.resumeBody {
    padding-left: 23vw;
    padding-right: 23vw;
    padding-top: 16vh;
    h1 {
        font-size: 48px;
    }
    p {
        font-size: 40px;
    }
    a {
        color: $highlight;
        &:hover,
        &:focus {
            filter: brightness(1.5);
        }
    }
}
.annotationLayer {
    pointer-events: auto !important;
}
/* end of other pages */

/* footer */
.copyrightText {
    font-size: 24px;
    text-align: center;
}
footer {
    padding-bottom: 2vh;
    a,
    p {
        color: $highlight;
    }
}
.breakLine {
    width: 70vw;
    margin-left: 15vw;
    border-top: 3px solid $highlight;
}
.navFooterItems {
    padding: 25px;
    text-align: center;
    br {
        display: none;
    }
    a {
        font-size: 25px;
        padding: 20px;
        text-decoration: none;
        &:hover,
        &:focus {
            color: #bacfd8;
        }
    }
}
/* end of footer */
/* when smaller screen */
@media only screen and (max-width: 1270px) {
    .model {
        left: 0vw;
    }
    .mainPage p,
    .about a {
        font-size: 28px;
    }
    .singleBody {
        grid-template-columns: 26vw 50vw;
        h1 {
            font-size: 60px;
        }
        p {
            font-size: 36px;
        }
        a {
            font-size: 36px;
        }
    }
    .singleBodyCoin {
        grid-template-columns: 30vw 50vw;
    }
    .mainContact a {
        font-size: 40px;
    }
}
@media only screen and (max-width: 1050px) {
    /* sideThing */
    .inline p {
        font-size: 40px;
    }
    .blueSide {
        width: 7vw;
        border-right: 10px solid $primary;
    }
    .lightBlueSide {
        width: 7vw;
        border-right: 10px solid #ade0f5;
    }
    .extraMargin {
        margin-left: 10vw;
    }
    .pageText h1 {
        font-size: 48px;
    }
    .pageText .singleText {
        font-size: 30px;
    }
    .intro {
        grid-template-columns: 22vw 50vw;
    }
    .model {
        left: -8vw;
        min-width: auto;
    }
    .singleProject {
        padding-bottom: 50px;
    }
    .mainPage > div {
        padding-left: 12vw;
    }
    .mainContact {
        padding-left: 12vw;
    }
    .singleBody {
        display: block;
        text-align: center;
        padding-top: 15vh;
    }
    .singleBody h1 {
        margin-top: 10px;
    }
    .navbar {
        background-color: #21282b;
        padding: 15px;
        a {
            font-size: 24px;
            padding: 8px;
        }
    }
    .navItems {
        display: none;
    }
    .hamburger {
        display: block;
    }
    .resumeBody {
        padding-left: 15vw;
        padding-right: 15vw;
        padding-top: 10vh;
        p {
            font-size: 24px;
        }
    }
    .inline {
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width: 800px) {
    .model {
        top: 20vh;
        height: 50vh;
        left: -8vw;
    }
    .about {
        padding-top: 20px;
        padding-right: 10vw;
    }
    .mainPage {
        h2 {
            font-size: 44px;
        }
        h3 {
            font-size: 36px;
        }
        h4 {
            font-size: 36px;
        }
        p {
            font-size: 24px;
        }
    }
    .mainPage a,
    .contact a {
        font-size: 28px;
    }
    .navFooterItems a {
        font-size: 1em;
    }
    .navIcon {
        height: 24px;
    }
    .copyrightText {
        font-size: 1em;
    }
    .breakLine {
        width: 60vw;
        margin-left: 20vw;
        border-top: 2px solid $highlight;
    }
}
@media only screen and (max-width: 530px) {
    .model {
        left: -25vw;
    }
    .extraMargin {
        margin-left: 25vw;
    }
    .mainPage h3 {
        font-size: 24px;
    }
    .mainPage p,
    .about a,
    .endOfProjects p a {
        font-size: 20px;
    }
    .block {
        height: auto;
    }
    .introContainer {
        height: 100vh;
    }
    .mainContact {
        padding-right: 10px;
        a {
            font-size: 20px;
        }
    }
    #footerBreak {
        display: block;
    }
    .navbar a {
        font-size: 20px;
    }
    .pageIcons {
        max-width: 70vw;
    }
    .pageText h1 {
        font-size: 36px;
    }
    .mainPage .pageText p {
        font-size: 20px;
    }
    .pageText a {
        font-size: 20px;
    }
    .homeIcon svg {
        height: 30px;
    }
}
@media only screen and (max-width: 400px) {
    .about {
        padding-right: 20px;
    }
    .about p,
    .about a {
        font-size: 16px;
    }
}
/* why use a phone less than this size :p */
@media only screen and (max-width: 361px) {
    .intro .pageText h1 {
        font-size: 28px;
    }
    .intro .pageText p {
        font-size: 18px;
    }
}
/* animations */
.header1 {
    visibility: hidden;
    vertical-align: top;
}
.type div {
    display: inline;
}
.inline {
    display: grid;
    grid-template-columns: 90px auto;
    p {
        margin-bottom: auto;
        margin-top: auto;
        padding-right: 10px;
        font-size: 50px;
    }
}
/* .singleText .rotator .first {
    display: inherit;
} */
/* slide ins */
/* keyframes */
@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* secret */
